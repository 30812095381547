const loadComponents = (root) => {
  const componentsArray = Array.from(root.querySelectorAll('[data-component]'))

  componentsArray.forEach((component) => {
    const componentName = component.getAttribute('data-component')

    import(`../components/${componentName}.js`).then((module) => {
      const Component = module.default
      const componentInstance = new Component(component)

      componentInstance.init()
    })
  })
}

export default loadComponents
