import 'lazysizes'
import loadComponent from './global/loadComponents'

class Site {
  constructor() {
    this.init()
  }

  init() {
    loadComponent(document)
  }
}

// if ('scrollRestoration' in history) {
//   history.scrollRestoration = 'manual'
// }

window.addEventListener('load', () => {
  // window.scrollTo(0, 0)
  new Site()
})
